
import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

export const ServerContext = createContext();

const initialState = {
  loading: false,
  initialized: false,
  servers: [],
  currentServer: null,
  error: null
};

const baseServerSettings = { 
  attributes: {
    gameMode: "Unknown",
    map: "Unknown",
    onlinePlayers: 0,
    maxPlayers: 1,
    ip: "Unknown",
    serverName: "Unknown",
  }
}
// Reducer

export const SERVER_LIST_INITIALIZING = "SERVER_LIST_INITIALIZING";
export const SERVER_LIST_ACQUIRED = "SERVER_LIST_ACQUIRED";
export const SERVER_LIST_INITIALIZED = "SERVER_LIST_INITIALIZED";
export const SET_CURRENT_SERVER = "SET_CURRENT_SERVER";
export const CLEAR_CURRENT_SERVER = "CLEAR_CURRENT_SERVER";

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SERVER_LIST_INITIALIZING:
      return {...state,  loading: true, initialized: false};
    case SERVER_LIST_INITIALIZED:
      if (!state.initialized) {
        return {...state,  loading: false, initialized: true};
      } else {
        return state;
      }
    case SERVER_LIST_ACQUIRED:
      if (!payload) {
        console.error('payload is required!');
        return state;
      }
      // Ensure that the server list has the expected fields
      let fixedServerList = [];
      payload.forEach(server => {
        fixedServerList.push(
          {
            ...baseServerSettings,
            ...server,
            attributes: {
              ...baseServerSettings.attributes,
              ...server.attributes
            }
          }
        )
      });
      return {...state, servers: fixedServerList};
    case SET_CURRENT_SERVER:
      if (!payload) {
        console.error('payload is required!');
        return state;
      }
      if (payload !== state.currentServer) {
        return {...state,  currentServer: payload};
      } else {
        return state;
      }
    case CLEAR_CURRENT_SERVER:
      if (state.currentServer !== null) {
        return {...state,  currentServer: null};
      } else {
        return state;
      }
    default:
      return state;
  }
};

// Provider
export const ServerContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ServerContext.Provider value={[state, dispatch]}>
      {props.children}
    </ServerContext.Provider>
  );
};

ServerContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};


export default ServerContext;
