
import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

export const SteamUserContext = createContext();

const initialState = {
  initialized: false, // Initialized = loading from cache
  requests: [], // Steam ID's that still need to be decoded
  userInfo: {}
};

// Reducer

export const INITIALIZE = "INITIALIZE";
export const CLEAR_REQUESTS = "CLEAR_REQUESTS";
export const CLEAR_USER_INFO = "CLEAR_USER_INFO";
export const CLEAR_ALL = "CLEAR_ALL";
export const ADD_REQUESTS = "ADD_REQUESTS";
export const ADD_REQUESTS_REFRESH = "ADD_REQUESTS_REFRESH";
export const ADD_USER_INFO = "ADD_USER_INFO";


const reducer = (state, action) => {
  const { type, payload } = action;

  // Variables used in reducer
  let newRequests;
  let newUserInfo;
  let requestIndex;

  switch (type) {
    case INITIALIZE:
      return {...state,  initialized: true};
    case CLEAR_REQUESTS:
      return {...state,  requests: []};
    case CLEAR_USER_INFO:
      return {...state,  userInfo: {}};
    case ADD_REQUESTS:
    case ADD_REQUESTS_REFRESH:
      if (!payload) {
        console.error('payload is required!');
        return state;
      }
      
      newRequests = [];
      payload.forEach(request => {
        if (state.requests.indexOf(request) === -1 && (type === ADD_REQUESTS_REFRESH || !(request in state.userInfo))) {
          newRequests.push(request);
        }
      });

      if (newRequests.length === 0) {
        return state;
      }

      return {...state, requests: [...state.requests, ...newRequests]};
    case ADD_USER_INFO:
      if (!payload) {
        console.error('payload is required!');
        return state;
      }
      newUserInfo = state.userInfo;
      newRequests = state.requests;
      payload.forEach(userInfo => {
        newUserInfo[userInfo.steamid] = {...userInfo, timestamp: Date.now()};
        requestIndex = newRequests.indexOf(userInfo.steamid);
        if (requestIndex !== -1) {
          newRequests.splice(requestIndex, 1);
        } 
      });
      return {...state, requests: [...newRequests], userInfo: {...newUserInfo}};
    default:
      return state;
  }
};

// Provider
export const SteamUserContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SteamUserContext.Provider value={[state, dispatch]}>
      {props.children}
    </SteamUserContext.Provider>
  );
};

SteamUserContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};


export default SteamUserContext;
