import React, {
  useState,
  useEffect
} from 'react';
import Cookies from 'js-cookie';
import {
  Box,
  Button,
  Portal,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    maxWidth: 600,
    position: 'fixed',
    bottom: 0,
    left: 0,
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    outline: 'none',
    zIndex: 2000
  },
  action: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  }
}));

function CookiesNotification() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const handleClose = () => {
    Cookies.set('consent', 'true');
    setOpen(false);
  };

  const cookiePolicy = () => {
    history.push('/docs/cookie-policy');
  };

  useEffect(() => {
    const consent = Cookies.get('consent');

    if (!consent) {
      setOpen(true);
    }
  }, []);

  if (!open) {
    return null;
  }

  return (
    <Portal>
      <div className={classes.root}>
        <Typography
          variant="body1"
          color="inherit"
        >
          This website or its third-party tools process personal data (e.g. browsing data or IP addresses) and use cookies or other identifiers, which are necessary for its functioning and required to achieve the purposes illustrated in the cookie policy.
You accept the use of cookies or other identifiers by closing or dismissing this notice, by scrolling this page, by clicking a link or button or by continuing to browse otherwise.

        </Typography>
        <Box
            mt={2}
            display="flex"
            justifyContent="space-between">
          <Box>
            <Button
              onClick={cookiePolicy}
              variant="contained"
              className={classes.action}
            >
              Cookie Policy
            </Button>
          </Box>
          <Box>
            <Button
              onClick={handleClose}
              variant="contained"
              className={classes.action}
            >
              I Agree
            </Button>
          </Box>
        </Box>
      </div>
    </Portal>
  );
}

export default CookiesNotification;
