import React, {
  useContext,
  useEffect,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import SplashScreen from 'src/components/SplashScreen';
import { setUserData, logout } from 'src/actions/accountActions';
import authService from 'src/services/authService';

import * as firebase from "firebase/app";
import "firebase/auth";

import UserContext, { LOGGED_IN, USER_INITIALIZED, LOGGED_OUT } from 'src/context/UserContext';
import SteamUserContext, { ADD_REQUESTS } from 'src/context/SteamUserContext';

function Auth({ children }) {
  const dispatch = useDispatch();
  const [ , userDispatch ] = useContext(UserContext);
  const [ , steamUserDispatch ] = useContext(SteamUserContext);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        userDispatch({type: LOGGED_IN, payload: user});
        userDispatch({type: USER_INITIALIZED});

        steamUserDispatch({type: ADD_REQUESTS, payload: [user.uid]})
      } else {
        userDispatch({type: LOGGED_OUT, payload: user});
        userDispatch({type: USER_INITIALIZED});
      }
    });
    return () => {
      unsubscribe();
    }
  }, [userDispatch, steamUserDispatch]);

  useEffect(() => {
    const initAuth = async () => {
      authService.setAxiosInterceptors({
        onLogout: () => dispatch(logout())
      });

      authService.handleAuthentication();

      if (authService.isAuthenticated()) {
        const user = await authService.loginInWithToken();

        await dispatch(setUserData(user));
      }

      setLoading(false);
    };

    initAuth();
  }, [dispatch]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.any
};

export default Auth;
