import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import UserContext from 'src/context/UserContext';
import ServerContext, { SERVER_LIST_INITIALIZING, SERVER_LIST_ACQUIRED, SERVER_LIST_INITIALIZED } from 'src/context/ServerContext';
import * as firebase from "firebase/app";
import "firebase/firestore";
import { cleanUpServerLogs } from 'src/db';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

let db;
let serverCollection; 
function DashboardLayout({ children }) {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  //const [serverCollection, setServerCollection] = useState();

  const [ userState ] = useContext(UserContext);
  const [ , serverDispatch ] = useContext(ServerContext);
  

  useEffect(() => {
    // Only grab servers when logged in
    if (!userState.isLoggedIn) {
      return;
    }
    
    // Start the "Loading" process 
    serverDispatch({type: SERVER_LIST_INITIALIZING});
    
    // Create  the observer
    db = firebase.firestore();
    serverCollection = db.collection('servers').where('staff', 'array-contains', userState.steamId)
    const unsubscribe = serverCollection.onSnapshot(serverSnapshot => {
      let servers = [];
      serverSnapshot.forEach(function(doc) {
        servers.push({id: doc.id, ...doc.data()});
      });

      serverDispatch({type: SERVER_LIST_ACQUIRED, payload: servers});
      serverDispatch({type: SERVER_LIST_INITIALIZED});
      cleanUpServerLogs(servers);
    }, err => {
      console.log(`Encountered error: ${err}`);
    })

    // Return the unsubscribe cleanup function
    return () => {
      unsubscribe();
    }
  }, [userState.isLoggedIn, userState.steamId, serverDispatch])

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any
};

export default DashboardLayout;
