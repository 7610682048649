/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect, useContext, useState } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  PieChart as PieChartIcon,
  Monitor as ServersIcon,
} from 'react-feather';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import UserContext from 'src/context/UserContext';
import SteamAvatar from 'src/components/SteamAvatar';
import SteamUser from 'src/components/SteamUser';
import ServerContext from 'src/context/ServerContext';

const navConfig = [
  {
    subheader: 'Overview',
    items: [
      {
        title: 'Dashboard',
        icon: PieChartIcon,
        href: '/dashboard'
      }
    ]
  },
  {
    subheader: 'Management',
    items: [
      {
        title: 'Servers',
        icon: ServersIcon,
        href: '/server',
        serverList: true,
        items: [
          // {
          //   title: 'List Servers',
          //   href: '/server'
          // }
        ]
      }
    ]
  }
];

function renderNavItems({ items, serverList, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, serverList, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  serverList,
  depth = 0
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={item.key ? item.key : key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.serverList ? [...item.items, ...serverList] : item.items,
          serverList: serverList
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={item.key ? item.key : key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

function truncateString(str, num) {
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

function NavBar({ openMobile, onMobileClose, }) {
  const classes = useStyles();
  const location = useLocation();
  const [ userState ] = useContext(UserContext);
  const [ serverState ] = useContext(ServerContext);
  const [ serverNavConfigList, setServerNavConfigList] = useState([]);

  

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    let serverList = [];
    
    serverState.servers.forEach(server => {
      serverList.push({
        title: truncateString(server.attributes.serverName, 20),
        href: `/server/${server.id}`,
        key: server.id
      })
    });

    setServerNavConfigList(serverList);
  }, [serverState.servers, setServerNavConfigList]);


  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/app/account">
              <SteamAvatar
                alt="User"
                className={classes.avatar}
                steamId={userState.steamId}
              />
            </RouterLink>
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              <SteamUser steamId={userState.steamId}/>
            </Link>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              {/* Bio Stuff Here */}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig.map((config) => (
            <List
              key={config.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {config.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({ items: config.items, pathname: location.pathname, serverList: serverNavConfigList })}
            </List>
          ))}
        </Box>
        <Divider />
        <Box p={2}>
          <Box
            p={2}
            borderRadius="borderRadius"
            bgcolor="background.dark"
          >
            <Typography
              variant="h6"
              color="textPrimary"
            >
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="primary"
              component={RouterLink}
              to="/docs"
            >
              Check our docs
            </Link>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
