import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import 'src/assets/css/prism.css';
import 'src/mixins/chartjs';
import 'src/mixins/prismjs';
import 'src/mock';
import { enableES5 } from 'immer';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from 'src/serviceWorker';
import { SettingsProvider } from 'src/context/SettingsContext';
import { configureStore } from 'src/store';
import { restoreSettings } from 'src/utils/settings';
import App from 'src/App';
import * as firebase from "firebase/app";
import { UserContextProvider } from './context/UserContext';
import { ServerContextProvider } from './context/ServerContext';
import { SteamUserContextProvider } from './context/SteamUserContext';
import { WebsocketContextProvider } from './context/WebsocketContext';

enableES5();

const firebaseConfig = {
  apiKey: "AIzaSyBB5ax_7cjuL-hMjzuE81SQjF4ew4vUFwQ",
  authDomain: "gmod-cloud.firebaseapp.com",
  databaseURL: "https://gmod-cloud.firebaseio.com",
  projectId: "gmod-cloud",
  storageBucket: "gmod-cloud.appspot.com",
  messagingSenderId: "870159016103",
  appId: "1:870159016103:web:d5f4b55a448dfbf6b7fb82",
  measurementId: "G-37PG84DFFQ"
};
firebase.initializeApp(firebaseConfig);

const store = configureStore();
const settings = restoreSettings();

ReactDOM.render(
  <Provider store={store}>
    <SettingsProvider settings={settings}>
      <UserContextProvider>
        <ServerContextProvider>
          <SteamUserContextProvider>
            <WebsocketContextProvider>
              <App />
            </WebsocketContextProvider>
          </SteamUserContextProvider>
        </ServerContextProvider>
      </UserContextProvider>
    </SettingsProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
