
import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

export const WebsocketContext = createContext();

const initialState = {
  initialized: false, // Initialized = loading from cache
  isConnected: false,
  authenticationPending: false,
  isAuthenticated: false,
  connectedServers: [],
  error: false
};

// Reducer

export const INITIALIZE = "INITIALIZE";
export const WEBSOCKET_CONNECTED = "WEBSOCKET_CONNECTED";
export const WEBSOCKET_DISCONNECTED = "WEBSOCKET_DISCONNECTED";
export const AUTHENTICATION_PENDING = "AUTHENTICATION_PENDING";
export const NOT_AUTHENTICATED = "NOT_AUTHENTICATED";
export const AUTHENTICATED = "AUTHENTICATED";
export const SERVER_DISCONNECTED = "SERVER_DISCONNECTED";
export const SERVER_CONNECTED = "SERVER_CONNECTED";


const reducer = (state, action) => {
  // eslint-disable-next-line
  const { type, payload } = action;
  let index;

  switch (type) {
    case INITIALIZE:
      return {...state,  initialized: true};
    case WEBSOCKET_CONNECTED:
      return {...state,  isConnected: true};
    case WEBSOCKET_DISCONNECTED:
      return {...state,  isConnected: false};
    case AUTHENTICATION_PENDING:
      return {...state, authenticationPending: true, isAuthenticated: false};
    case AUTHENTICATED:
      return {...state, authenticationPending: false, isAuthenticated: true};
    case NOT_AUTHENTICATED:
      return {...state, authenticationPending: false, isAuthenticated: false, error: true};
    case SERVER_DISCONNECTED:
      if (!payload) {return state}
      index = state.connectedServers.indexOf(payload);
      if (index !== -1) {
        return {...state, connectedServers: state.connectedServers.filter((_, i) => i !== index)};
      } else {
        return state;
      }
    case SERVER_CONNECTED:
      if (!payload) {return state}
      index = state.connectedServers.indexOf(payload);
      if (index !== -1) {
        return state;
      } else {
        return {...state, connectedServers: [...state.connectedServers, payload]};
      }
    default:
      return state;
  }
};

// Provider
export const WebsocketContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <WebsocketContext.Provider value={[state, dispatch]}>
      {props.children}
    </WebsocketContext.Provider>
  );
};

WebsocketContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};


export default WebsocketContext;
