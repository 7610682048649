import React, { useContext, useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Auth from 'src/components/Auth';
import CookiesNotification from 'src/components/CookiesNotification';
// import GoogleAnalytics from 'src/components/GoogleAnalytics';
import ScrollReset from 'src/components/ScrollReset';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import Routes from 'src/Routes';
import SteamUserContext, {ADD_USER_INFO} from './context/SteamUserContext';
import axios from 'axios';
import moment from 'moment'
import db, { saveSteamUsers } from './db'
import { websocket } from 'src/websocket'
import UserContext from './context/UserContext';
import * as firebase from "firebase/app";
import "firebase/auth";
import WebsocketContext, { WEBSOCKET_CONNECTED, WEBSOCKET_DISCONNECTED, NOT_AUTHENTICATED, AUTHENTICATED, AUTHENTICATION_PENDING, SERVER_CONNECTED, SERVER_DISCONNECTED } from './context/WebsocketContext';

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      height: '100%',
      width: '100%'
    },
    '#root': {
      height: '100%',
      width: '100%'
    }
  }
}));

function App() {
  useStyles();

  const { settings } = useSettings();
  const [steamUsers, steamUsersDispatch] = useContext(SteamUserContext);
  const [userState, ] = useContext(UserContext);
  const [, websocketDispatch] = useContext(WebsocketContext);

  const [updatingSteamInfo, setUpdatingSteamInfo] = useState(false);

  // Steam User Info context
  useEffect(() => {
    // If nothing to do, return
    if (updatingSteamInfo || steamUsers.requests.length === 0) { 
      return;
    }
    setUpdatingSteamInfo(true);

    let staleOrEmptySteamUsers = [];
    let steamUsersToDispatch = [];
    const curTime = moment();

          // See if we have the steam ID's in the database
    db.transaction("r", db.steamUsers, async () => {
      for (const steamUid of steamUsers.requests) {
        const steamUser = await db.steamUsers.get({
          uid: steamUid
        });

        // If not in db or stale, get updated info
        if (steamUser === undefined || curTime.diff(moment(steamUser.updatedTimestamp), 'days') > 1) {
          staleOrEmptySteamUsers.push(steamUid);
        }

        if (steamUser !== undefined) { 
          steamUsersToDispatch.push(steamUser.object)
        }
      }

      // Dispatch our current/stale info
      steamUsersDispatch({type: ADD_USER_INFO, payload: steamUsersToDispatch});

      // Any database error event that occur will abort transaction and be sent to
      // the catch() method below.
    })
    .then(() => {
      // If we're all caught up via the db, no need to continue
      if (staleOrEmptySteamUsers.length === 0) {
        setUpdatingSteamInfo(false);
        return;
      }
      const steamIds = staleOrEmptySteamUsers.slice(0,100).join(',');
      axios.get(`/api/steam/get/${steamIds}`)
      .then(res => {
        saveSteamUsers(res.data);
        steamUsersDispatch({type: ADD_USER_INFO, payload: res.data});
        setUpdatingSteamInfo(false);
      })
    })
    .catch((err) => {
      // Log or display the error
      console.log("Error getting steam ids from db:", err);
      setUpdatingSteamInfo(false);
    });


  }, [steamUsers.initialized, steamUsers.requests, steamUsersDispatch, updatingSteamInfo])

  // Websocket
  useEffect(() => {
    websocket.on('connect', () => {
      websocketDispatch({type: WEBSOCKET_CONNECTED})
    })

    websocket.on('startAuthentication', () => {
      websocketDispatch({type: AUTHENTICATION_PENDING})
      firebase.auth().currentUser.getIdToken()
      .then((token) => {
        websocket.emit('authentication', token, (auth) => {
          if (auth.error === false && auth.validated === true) {
            websocketDispatch({type: AUTHENTICATED})
          } else {
            websocketDispatch({type: NOT_AUTHENTICATED})
          }
        })
      })
      .catch((error) => {
        console.log("Error getting firebase token");
      })
    })

    websocket.on('disconnect', () => {
      websocketDispatch({type: WEBSOCKET_DISCONNECTED})
    })

    websocket.on('serverDisconnected', (serverId) => {
      websocketDispatch({type: SERVER_DISCONNECTED, payload: serverId})
    })

    websocket.on('serverConnected', (serverId) => {
      websocketDispatch({type: SERVER_CONNECTED, payload: serverId})
    })
  }, [websocketDispatch])
  
  useEffect(() => {
    if (userState.isLoggedIn && !websocket.connected) {
      websocket.connect();
    }

    if (!userState.isLoggedIn && websocket.connected) {
      websocket.disconnect();
    }
  }, [userState.isLoggedIn])

  return (
    <ThemeProvider theme={createTheme(settings)}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider maxSnack={1}>
            <Router history={history}>
              <Auth>
                <ScrollReset />
                {/* <GoogleAnalytics /> */}
                <CookiesNotification />
                {/* <SettingsNotification /> */}
                <Routes />
              </Auth>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
