import React, {useState, useContext, useEffect, useRef} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import SteamAvatar from 'src/components/SteamAvatar';
import SteamUser from 'src/components/SteamUser';
import {
  Box,
  Grid,
  IconButton,
  Link,
  Paper,
  makeStyles,
  Typography,
  LinearProgress,
  Chip,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import {
  MoreVertical as MoreIcon
} from 'react-feather';
import ServerContext from 'src/context/ServerContext';
import * as firebase from "firebase/app";
import "firebase/firestore";
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import moment from 'moment';
import RemoveIcon from '@material-ui/icons/Remove';
import InviteIcon from '@material-ui/icons/Add';
import UserContext from 'src/context/UserContext';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    height: 42,
    width: 42
  },
  loading: {
    marginTop: theme.spacing(1),
    display: "block",
    width: "100%"
  },
  grid: {
    paddingBottom: theme.spacing(1)
  },
  usergroup: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));

let db;

function SteamSearchResultUser({steamId}) {
  const classes = useStyles();
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [serverState] = useContext(ServerContext);
  const [userState] = useContext(UserContext);
  
  const [lastServer, setLastServer] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [serverSet, setServerSet] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isMountedRef = useIsMountedRef();

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };
  
  
  const addStaff = (steamId) => {
    setOpenMenu(false);
    db.collection('servers').doc(lastServer).update({
      staff: firebase.firestore.FieldValue.arrayUnion(steamId)
    })
  }

  const removeStaff = (steamId) => {
    setOpenMenu(false);
    db.collection('servers').doc(lastServer).update({
      staff: firebase.firestore.FieldValue.arrayRemove(steamId)
    })
  }

  useEffect(() => {
    if (serverState.currentServer === null) {
      setServerSet(false);
      setIsLoading(false);
    } else if (lastServer !== serverState.currentServer.id) {
      setLastServer(serverState.currentServer.id);
      setIsLoading(true);
      setServerSet(true);
    }
    // eslint-disable-next-line
  }, [serverState])

  useEffect(() => {
    if (lastServer === null) { 
      return;
    }

    db = firebase.firestore();
    
    let playerRef = db.collection('servers').doc(lastServer)
                      .collection('players').doc(steamId);
    playerRef.get()
      .then(doc => {
        if (!doc.exists) {
          if (isMountedRef.current) {
            setUserInfo(null);
            setIsLoading(false);
          }
        } else {
          if (isMountedRef.current) {
            const data = doc.data();
            setUserInfo(data);
            setIsLoading(false);
          }
        }
      })
      .catch(err => {
        if (isMountedRef.current) {
          console.log("Error getting user info: ", err);
          setUserInfo(null);
          setIsLoading(false);
        }
      });

    // eslint-disable-next-line
  }, [lastServer])

  return (
    <Grid
    item
    key={steamId}
    xs={12}
    className={classes.grid}
  >
    <Paper variant="outlined">
      <Box
        p={2}
        display="flex"
        alignItems="center"
      >
        <SteamAvatar
          alt="Staff"
          className={classes.avatar}
          component={RouterLink}
          steamId={steamId}
          to="#"
        />
        <Box
          flexGrow={1}
          mx={2}
        >
          <Link
            variant="h5"
            color="textPrimary"
            component={RouterLink}
            to="#"
          >
            <SteamUser steamId={steamId}/>
          </Link>
          {isLoading && 
            <LinearProgress className={classes.loading} />
          }
          {serverSet && !isLoading && !userInfo && 
            <Typography
              variant="body2"
              color="textSecondary"
              gutterBottom
            >
              No server information for user
            </Typography>
          }
          {serverSet && !isLoading && userInfo && 
            <Typography
              variant="body2"
              color="textSecondary"
              gutterBottom
            >
            First seen {moment.unix(userInfo.firstSeenTimestamp.seconds).from(moment())}
            </Typography>
          }
        </Box>
        {serverSet && !isLoading && userInfo && <Chip className={classes.usergroup} size="small" color="secondary" label={userInfo.usergroup} variant="outlined" />}

        {lastServer !== null && serverState.currentServer && serverState.currentServer.owner === userState.steamId && 
        (
          <Tooltip title="More options">
            <IconButton
              onClick={handleMenuOpen}
              className={classes.action}
              ref={moreRef}
            >
              <MoreIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      
        <Menu
          anchorEl={moreRef.current}
          onClose={handleMenuClose}
          open={openMenu}
          PaperProps={{ className: classes.menu }}
        >
          {serverState.currentServer && serverState.currentServer.owner === userState.steamId
          && serverState.currentServer.staff.indexOf(steamId) === -1 && (
            <MenuItem
              disabled={userState.steamId === steamId}
              onClick={() => {addStaff(steamId)}}
            >
              <ListItemIcon>
                <InviteIcon />
              </ListItemIcon>
              <ListItemText 
              primary="Add as Web Staff" />
            </MenuItem>
          )}
          {serverState.currentServer && serverState.currentServer.owner === userState.steamId
          && serverState.currentServer.staff.indexOf(steamId) !== -1 && (
            <MenuItem
              disabled={userState.steamId === steamId}
              onClick={() => {removeStaff(steamId)}}
            >
              <ListItemIcon>
                <RemoveIcon />
              </ListItemIcon>
              <ListItemText 
              primary="Remove as Web Staff" />
            </MenuItem>
          )}
        </Menu>
      </Box>
    </Paper>
  </Grid>
  );
}


export default SteamSearchResultUser;
