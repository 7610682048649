import React, {useContext, useEffect, useState} from 'react';
import SteamUserContext, { ADD_REQUESTS } from 'src/context/SteamUserContext';
import SteamID from 'steamid';

function SteamUser({steamId}) {
  const [steamUser, steamUserDispatch] = useContext(SteamUserContext);
  const [hasSteamUserInfo, setHasSteamUserInfo] = useState(false)
  const [invalidId, setInvalidId] = useState(false)

  const [steamid64, setSteamid64] = useState(null);

  useEffect(() => {
    const sid = new SteamID(steamId);
    if (sid.isValid()) {
      setSteamid64(sid.getSteamID64());
    } else {
      setInvalidId(true);
    }
  }, [steamId])

  useEffect(() => {
    if (hasSteamUserInfo || steamid64 === null || steamid64 === undefined) {
      return;
    }

    if (steamid64 in steamUser.userInfo) {
      setHasSteamUserInfo(true);
    } else if (steamUser.requests.indexOf(steamid64) === -1) {
      steamUserDispatch({type: ADD_REQUESTS, payload: [steamid64]});
    }
  }, [steamUser.userInfo, steamUser.requests, steamUserDispatch, hasSteamUserInfo, steamid64])

  if (invalidId) {
    return <>Invalid Steam ID</>
  }

  return (
    <>{hasSteamUserInfo ? steamUser.userInfo[steamid64].personaname : steamId}</>
  );
}

export default SteamUser;
