import Dexie from 'dexie';

const db = new Dexie('GmodCloudDB');
db.version(1).stores({ 
  steamUsers: '&uid, updatedTimestamp',
  servers: '&serverId',
  serverLogs: '[serverId+logType+timestamp],[serverId+logType],serverId, logType, &docId, timestamp' 
});

export function cleanUpServerLogs(servers) {
  servers.forEach(async server => {
    let serverLogCount = await db.serverLogs.where("serverId").equals(server.id).count()
    
    if (serverLogCount > 5000) { 
      db.serverLogs
      .orderBy('timestamp')
      .filter(row => row.serverId === server.id)
      .limit(Math.max(0, serverLogCount - 3000))
      .delete()
      .then(function (deleteCount) {
          console.log( "Deleted " + deleteCount + " old server logs");
      });
    }
  })
}

async function cleanUpSteamUsers() {
  let steamUserCount = await db.steamUsers.count()
  if (steamUserCount > 1000) { 
    console.log("Deleting steam ID's");
    db.steamUsers
    .orderBy('updatedTimestamp')
    .limit(Math.max(0, steamUserCount - 800))
    .delete()
    .then(function (deleteCount) {
        console.log( "Deleted " + deleteCount + " old steam ID's");
    });

  }
}

export function saveSteamUsers(steamUsers) {
  db.transaction("rw", db.steamUsers, async () => {
    steamUsers.forEach(steamUser => {
      db.steamUsers.put({
        uid: steamUser.steamid,
        updatedTimestamp: Date.now(),
        object: steamUser
      })
    })
  })
  .then(() => {
    console.log("Saved steam ID's");
    cleanUpSteamUsers();
  })
  .catch(err => {
    console.log("Error saving Steam ID's: ", err);
  })
}

export default db;