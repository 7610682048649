
import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

export const UserContext = createContext();

const initialState = {
  initialized: false,
  isLoggedIn: false,
  steamId: null,
  error: null,
};

// Reducer
export const LOGGED_IN = "LOGGED_IN";
export const LOGGED_OUT = "LOGGED_OUT";
export const START = "START";
export const STOP = "STOP";
export const USER_INITIALIZED = "USER_INITIALIZED";

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGGED_IN:
      if (!payload) {
        console.error('payload is required!');
        return state;
      }
      return {...state, steamId: payload.uid, isLoggedIn: true};
    case LOGGED_OUT:
      return {...state, isLoggedIn: false};
    case USER_INITIALIZED:
      return {...state, initialized: true};
    default:
      return state;
  }
};

// Provider
export const UserContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <UserContext.Provider value={[state, dispatch]}>
      {props.children}
    </UserContext.Provider>
  );
};

UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};


export default UserContext;
