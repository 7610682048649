import React, {useEffect, useState, useContext} from 'react';
import db from 'src/db'
import SteamID from 'steamid';
import SteamUserContext, { ADD_USER_INFO, ADD_REQUESTS } from 'src/context/SteamUserContext';
import {
  Box,
  CircularProgress,
} from '@material-ui/core';
import SteamSearchResultUser from './SteamSearchUserResult';
import useIsMountedRef from 'src/hooks/useIsMountedRef';


function SteamSearchView({searchQuery}) {
  const [, steamUserDispatch] = useContext(SteamUserContext);
  const [results, setResults] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    if (searchQuery.length > 0) {
      if (!isLoading) {
        setIsLoading(true);
      }
    } else { 
      if (isLoading) {
        setIsLoading(false);
      }
      if (results.length > 0){
        setResults([]);
      }
    }
    // eslint-disable-next-line
  }, [searchQuery])

  useEffect(() => {
    if (!isLoading) { return }
    let newResults = []
    db.steamUsers.filter(function (steamUser) {
      if (steamUser.uid.includes(searchQuery)) {
        return true
      }
      if (steamUser.object.personaname.toLowerCase().includes(searchQuery.toLowerCase())) {
        return true
      }
      return false;
    })
    .toArray()
    .then((searchResults) => {
      
      // Dispatch our current/stale info
      steamUserDispatch({type: ADD_USER_INFO, payload: searchResults.map((val) => val.object)});

      newResults.push(...searchResults.map((val) => val.uid))
      if (searchQuery.length === 17) {
        var sid = new SteamID(searchQuery);
        if (sid.isValid()) {
          const steamId = sid.getSteamID64() 
          if (newResults.indexOf(steamId) === -1) {
            newResults.push(steamId);
          }
        }
      }
      if (isMountedRef.current) {
        newResults = newResults.slice(0, 10);
        steamUserDispatch({type: ADD_REQUESTS, payload: newResults});
        setResults(newResults);
        setIsLoading(false);
      }
    })
    .catch((err) => {
      if (isMountedRef.current) {
        console.log("Search error: ", err);
        setIsLoading(false);
        setResults([]);
      }
    })
    
    // eslint-disable-next-line
  }, [searchQuery, isLoading]);

  if (isLoading) {
    return (
    <Box
      display="flex"
      justifyContent="center"
    >
      <CircularProgress />
    </Box>
    );
  }

  if (results.length === 0)
  {
    return <></>
  }

  return (
    <>
      {results && results.map((result) => (
        <SteamSearchResultUser key={result} steamId={result}/>
      ))}
    </>
  );
}

export default SteamSearchView;
