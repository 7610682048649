import React, {useContext, useEffect, useState} from 'react';
import SteamUserContext, { ADD_REQUESTS } from 'src/context/SteamUserContext';
import { Avatar } from '@material-ui/core';
import SteamID from 'steamid';

function SteamAvatar({steamId, ...props}) {
  const [steamUser, steamUserDispatch] = useContext(SteamUserContext);
  const [hasSteamUserInfo, setHasSteamUserInfo] = useState(false)
  const [steamid64, setSteamid64] = useState(null);

  useEffect(() => {
    setSteamid64(new SteamID(steamId).getSteamID64())
  }, [steamId])

  useEffect(() => {
    if (hasSteamUserInfo || steamid64 === null) {
      return;
    }

    if (steamid64 in steamUser.userInfo) {
      setHasSteamUserInfo(true);
    } else if (steamUser.requests.indexOf(steamid64) === -1) {
      steamUserDispatch({type: ADD_REQUESTS, payload: [steamid64]});
    }
  }, [steamUser.userInfo, steamUser.requests, steamUserDispatch, hasSteamUserInfo, steamid64])

  return (
    <Avatar src={hasSteamUserInfo ? steamUser.userInfo[steamid64].avatarfull : ""} {...props}/>
  );
}

export default SteamAvatar;
