import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import UserContext from 'src/context/UserContext';
import ServerContext, { SERVER_LIST_INITIALIZING, SERVER_LIST_ACQUIRED, SERVER_LIST_INITIALIZED } from 'src/context/ServerContext';
// import { websocket } from 'src/websocket'
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import LoadingScreen from 'src/components/LoadingScreen';

let db;
let serverCollection; 
function PopupLayout({ history, children }) {

  const [ userState ] = useContext(UserContext);
  const [ , serverDispatch ] = useContext(ServerContext);
  const [ initialized ] = useState(true);
  const [ error, setError ] = useState(false);

  useEffect(() => {
    // Only grab servers when logged in
    if (!userState.isLoggedIn) {
      return;
    }
    
    // Start the "Loading" process 
    serverDispatch({type: SERVER_LIST_INITIALIZING});
    
    // Create  the observer
    db = firebase.firestore();
    serverCollection = db.collection('servers').where('staff', 'array-contains', userState.steamId)
    const unsubscribe = serverCollection.onSnapshot(serverSnapshot => {
      let servers = [];
      serverSnapshot.forEach(function(doc) {
        servers.push({id: doc.id, ...doc.data()});
      });

      serverDispatch({type: SERVER_LIST_ACQUIRED, payload: servers});
      serverDispatch({type: SERVER_LIST_INITIALIZED});
    }, err => {
      console.log(`Encountered error: ${err}`);
      setError(true);
    })

    // Return the unsubscribe cleanup function
    return () => {
      unsubscribe();
    }
  }, [userState.isLoggedIn, userState.steamId, serverDispatch])

  if (!initialized) {
    return <LoadingScreen/>
  }

  if (error) {
    return history.push('/404');
  }

  return children;
}

PopupLayout.propTypes = {
  children: PropTypes.any
};

export default PopupLayout;
