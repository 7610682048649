import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Button,
  Box,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 128,
    paddingBottom: 128
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

function Features({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          component="p"
          variant="overline"
          color="secondary"
          align="center"
        >
          Explore Gmod Cloud Admin Suite
        </Typography>
        <Typography
          variant="h1"
          align="center"
          color="textPrimary"
        >
          Not just a pretty face
        </Typography>
        <Box mt={8}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <Avatar className={classes.avatar}>
                  01
                </Avatar>
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                  >
                    Seamless install
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                  >
                    Simply drag-and-drop our plugin and GW Sockets to your server. Done. No editing or configuration necessary.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <Avatar className={classes.avatar}>
                  02
                </Avatar>
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                  >
                    Lives on the cloud
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                  >
                    Utilizing the most modern cloud infrastructure, Gmod Cloud will always be there for you.
                    Reap the benefits as well with live updates - no refresh needed!
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <Avatar className={classes.avatar}>
                  03
                </Avatar>
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                  >
                    Mobile Friendly
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    gutterBottom
                  >
                    We didn't think it was sufficient to be great on the desktop. Monitor your site from anywhere straight from your phone or tablet!
                    Gmod Cloud is designed to work around your life, not the other way around.
                  </Typography>
                  <Button
                    variant="outlined"
                    component="a"
                    href="https://www.gmodstore.com/market/view/gmod-cloud-web-admin-suite"
                    target="_blank"
                  >
                    Purchase now
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

Features.propTypes = {
  className: PropTypes.string
};

export default Features;
