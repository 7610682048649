import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import UserContext from 'src/context/UserContext';
import LoadingScreen from './LoadingScreen';

function GuestGuard({ children }) {
  const [userState] = useContext(UserContext);


  if (!userState.initialized) {
    return <LoadingScreen/>
  }

  if (userState.isLoggedIn) {
    return <Redirect to="/dashboard" />;
  }
  
  return children;
}

GuestGuard.propTypes = {
  children: PropTypes.any
};

export default GuestGuard;
