import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import UserContext from 'src/context/UserContext';
import LoadingScreen from './LoadingScreen';

function AuthGuard({ children }) {
  const [userState] = useContext(UserContext);


  if (!userState.initialized) {
    return <LoadingScreen/>
  }
  
  if (!userState.isLoggedIn) {
    return <Redirect to="/login" />;
  }

  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.any
};

export default AuthGuard;
